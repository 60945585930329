import React, { useContext, useEffect } from "react";
import { FaMoon } from "react-icons/fa";
import { MdLightMode } from "react-icons/md";
import { Context } from "../../context/store";
import {getFromLocalStorage, setLocalStorage} from '../utils/local-storage'; 

const ThemeSwitcher = (props) => {
    const [state, dispatch] = useContext(Context);

    const changeMode = (newMode) => {
        dispatch({type: "SET", key: "currentmode", payload: newMode});
        setLocalStorage("currentmode", newMode, 86400)

    }

    let currentmode = getFromLocalStorage("currentmode");

    useEffect(() => {
        if (currentmode != null){
            dispatch({type:"SET", key: "currentmode", payload: currentmode});
        } else {
            dispatch({type:"SET", key: "currentmode", payload: "dark"});
        }
    }, []);

    return (
        <>
            <span className="ml-3" style={{color: "rgba(255, 255, 0, 1)"}}>{state?.currentmode == "light" ? <FaMoon onClick={() => changeMode("dark")} size={20} className="inline-block"/> : <MdLightMode onClick={() => changeMode("light")} size={20} className="inline-block"/>}</span>
        </>
    )
}

export default React.memo(ThemeSwitcher);