import React, {useEffect, useCallback, useState, useContext, useRef} from 'react';
import {useNavigate} from "react-router-dom"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {Context} from '../../context/store';
import {getFromLocalStorage} from '../utils/local-storage';
import {ToastContainer} from 'react-toastify';
import makeRequest from '../utils/fetch-request';
import {setLocalStorage} from '../utils/local-storage';
import 'react-lazy-load-image-component/src/effects/blur.css';

import Logo from '../../assets/logo.svg';
import {Navbar, Nav, Offcanvas} from "react-bootstrap";
import SideBar from "../sidebar/awesome/Sidebar";
import {Menu, MenuItem, ProSidebar, SidebarContent, SidebarHeader, SubMenu} from "react-pro-sidebar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import SidebarMobile from "../sidebar/awesome/SidebarMobile";
import ThemeSwitcher from './mode-switcher';


const ProfileMenu = React.lazy(() => import('./profile-menu'));
const HeaderLogin = React.lazy(() => import('./top-login'));
const HeaderNav = React.lazy(() => import('./header-nav'));
const MobileLogin = React.lazy(() => import('./mobile-login-link'));
const MobileToggleMkts = React.lazy(() => import('./mobile-toggle-markets'));

const Header = (props) => {
    const [user, setUser] = useState(getFromLocalStorage("user"));
    const [state, dispatch] = useContext(Context);
    const history = useNavigate();
    const containerRef = useRef();
    const {current} = containerRef;
    const [competitions, setCompetitions] = useState({});

    const fetchData = useCallback(async () => {
        let cached_categories = getFromLocalStorage('categories');
        let endpoint = "/v1/categories";
        if (!cached_categories) {
            const [competition_result] = await Promise.all([
                makeRequest({url: endpoint, method: "get", data: null}),
            ]);
            let [c_status, c_result] = competition_result
            if (c_status === 200) {
                setCompetitions(c_result);
                setLocalStorage('categories', c_result);
            }
        } else {
            setCompetitions(cached_categories);
        }

    }, []);

    useEffect(()=>{
        setUser(getFromLocalStorage("user"));
    },[state?.user]);
        
    useEffect(() => {
        const abortController = new AbortController();
        fetchData();

        return () => {
            abortController.abort();
        };
    }, [fetchData]);


    const NotifyToastContaner = () => {
        return <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
    };
    const updateUserOnHistory = useCallback(() => {
        if (!user) {
            return false;
        }
        let endpoint = "/v1/balance";
        let udata = {
            token: user.token
        }
        makeRequest({url: endpoint, method: "post", data: udata}).then(([_status, response]) => {
            if ([200, 201, 204].includes(_status)) {
                let u = {...user, ...response.user};
                setLocalStorage('user', u);
                setUser(u)
                dispatch({type: "SET", key: "user", payload: user});
            }
        });

    }, [current, state?.balchange]);

    const updateUserOnLogin = useCallback(() => {
        dispatch({type: "SET", key: "user", payload: user});
    }, [user?.msisdn, user?.balance ]);


    useEffect(() => {
        updateUserOnHistory();
    }, [updateUserOnHistory, state?.balchange])

    const updateBal = () => {
        if (!user) {
            return false;
        }
        let endpoint = "/v1/balance";
        let udata = {
            token: user.token
        }
        makeRequest({url: endpoint, method: "post", data: udata}).then(([_status, response]) => {
            if ([200, 201, 204].includes(_status)) {
                let u = {...user, ...response.user};
                setLocalStorage('user', u);
                setUser(u)
                dispatch({type: "SET", key: "user", payload: user});
            }
        });
    }
    useEffect(() => {
        updateBal();
    }, [state?.balchange])

    useEffect(() => {
        updateUserOnLogin()
    }, [updateUserOnLogin])
    const expand = "md"
    return (
        <>

            <section className='secondary-bg text-white flex flex-row py-3' id="top-header">
                <div className='w-1/3 px-3'>
                    <span className='mr-3'><MobileToggleMkts /></span>
                    <a href="/" className="inline" title="">
                        <img src={Logo} className='w-5/12 inline'/> 
                    </a>
                </div>
                <div className='w-2/3 float-end px-3'>
                    <div className='float-end'>
                        <span className='inline-block'>{user ? <ProfileMenu user={user}/> : <HeaderLogin setUser={setUser}/>}</span>
                        <span className='inline-block'><ThemeSwitcher /></span>
                    </div>
                </div>

                
            </section>
            
            <section className='mb-4' style={{borderBottom:"1px solid rgba(255,255,255, 0.1)", boxShadow:"0px 3px 4px 0px rgba(0,0,0,0.05)", background:"rgba(255,255,255,0.051)"}}><HeaderNav/></section>
             
        </>

    )
}
export default React.memo(Header);
