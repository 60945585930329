import React from 'react';
import KaribuBonus from '../../assets/img/banner/1.jpg'

const CompanyInfo = (props) => {

    return (
        <div className="qv rc alu show-on-affix">
            <img src={KaribuBonus} width={"100%"}/>
        </div>
    )

}
export default CompanyInfo;
