import React, {useContext, useEffect, useCallback, useState, useRef} from "react";
import {useLocation} from 'react-router-dom';
import {Context} from '../context/store';
import makeRequest from './utils/fetch-request';
import {getBetslip} from './utils/betslip' ;
import useInterval from "../hooks/set-interval.hook";
import {Spinner} from "react-bootstrap";
import Row from 'react-bootstrap/Row';
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import useAnalyticsEventTracker from '../components/analytics/useAnalyticsEventTracker';


const Header = React.lazy(() => import('./header/header'));
const Footer = React.lazy(() => import('./footer/footer'));
// const SideBar = React.lazy(()=>import('./sidebar/sidebar'));
const CarouselLoader = React.lazy(() => import('./carousel/index'));
const IntroBlock = React.lazy(() => import('./indexSections/intro-block'));
const BonusBlock = React.lazy(() => import('./indexSections/bonus-link'));
const MainTabs = React.lazy(() => import('./header/main-tabs'));
const MatchList = React.lazy(() => import('./matches/index'));
const Right = React.lazy(() => import('./right/index'));
// This sidebar has been changed by css to navbar from sidebar
const SideBar = React.lazy(() => import('./sidebar/awesome/Sidebar'))
const Index = (props) => {
    const gaEventTracker = useAnalyticsEventTracker('Home');
    const location = useLocation();
    const [matches, setMatches] = useState([]);
    const [limit, setLimit] = useState(50);
    const [producerDown, setProducerDown] = useState(false);
    const [threeWay, setThreeWay] = useState(false);
    const [page, setPage] = useState(1);
    const [userSlipsValidation, setUserSlipsValidation] = useState();
    const [state, dispatch] = useContext(Context);
    const [fetching, setFetching] = useState(false)
    const homePageRef = useRef()
    const findPostableSlip = () => {
        let betslips = getBetslip() || {};
        var values = Object.keys(betslips).map(function (key) {
            return betslips[key];
        });
        if (betslips == {}){
            return null
        }
        return values;
    };

    useInterval(async () => {

        setFetching(true)

        let endpoint = "/v1/matches";

        let betslip = findPostableSlip();
        let method = "GET"; //betslip != [] ? "POST":"GET";
        let data = null; //betslip != [] ? betslip: null;

        let tab = location.pathname.replace("/", "") || 'today';

        endpoint += "?page=" + (page || 1) + `&limit=${limit || 50}&tab=` + tab

        let url = new URL(window.location.href)

        let sport_id = url.searchParams.get('sport_id')

        if (sport_id !== null) {
            endpoint += " &sport_id=" + sport_id
        }

        endpoint = endpoint.replaceAll(" ", '')

        endpoint += `&sub_type_id=` + (url.searchParams.get('sub_type_id') || "1,18,29")


        let search_term = url.searchParams.get('search')

        if (search_term !== null) {
            return
        }

        await makeRequest({url: endpoint, method: method, data: data}).then(([status, result]) => {
            if ([200, 204].includes(status)) {
                setMatches(matches.length > 0 ? {...matches, ...result?.data} : result?.data || result)
                setFetching(false)
                // setMatches(result?.data || result)
                if (result?.slip_data) {
                    setUserSlipsValidation(result?.slip_data)
                }
                setProducerDown(result?.producer_status === 1);
            }
        });
    }, 3000);

    const fetchData = useCallback(async () => {
        setFetching(true)
        let tab = location.pathname.replace("/", "") || 'today';
        let betslip = findPostableSlip();
        let endpoint = "/v1/matches?page=" + (page || 1) + `&limit=${limit || 50}&tab=` + tab;
        let url = new URL(window.location.href)
        let sport_id = url.searchParams.get('sport_id')

        if (sport_id !== null) {
            endpoint += " &sport_id=" + sport_id
        }

        endpoint = endpoint.replaceAll(" ", '')


        let search_term = url.searchParams.get('search')
        if (search_term !== null) {
            endpoint += ' &search=' + search_term
        }


        endpoint += `&sub_type_id=` + (url.searchParams.get('sub_type_id') || "1,18,29")


        await makeRequest({url: endpoint, method: "GET"}).then(([result]) => {
            if ([200, 204].includes()) {
                setMatches(matches.length > 0 ? {...matches, ...result?.data} : result?.data || result)
                setFetching(false)
                if (result?.slip_data) {
                    setUserSlipsValidation(result?.slip_data)
                }
                setProducerDown(result?.producer_status === 1);
            }
        });

    }, []);

    useEffect(() => {
        checkThreeWay()
        fetchData();
        let cachedSlips = getBetslip("betslip");
        if (cachedSlips) {
            dispatch({type: "SET", key: "betslip", payload: cachedSlips});
        }
        return () => {
            setMatches(null);
        };
    }, [fetchData]);

    const listInnerRef = useRef();

    const checkThreeWay = () => {
        let url = new URL(window.location)
        let sub_types = (url.searchParams.get('sub_type_id') || "1,18,29").split(",")
        setThreeWay(sub_types.includes("1"))
    }

    document.addEventListener('scrollEnd', (event) => {
        if (!fetching) {
            setFetching(true)
            setLimit(limit + 50)
        }
    })

    return (
        <>            
            
                        <div className="">
                            <div className="homepage" ref={homePageRef}>
                                <div className=""><CarouselLoader /></div>

                                <div className="my-3 px-3 rounded-md secondary-bg-2 std-box-shadow" style={{background:"#131E28"}}>
                                    <section className="event-row"><MainTabs tab={location.pathname.replace("/", "")}/></section>
                                    {/* <MobileCategories/> */}
                                    <div className="overflow-wrapper">
                                        <MatchList
                                            live={false}
                                            matches={matches}
                                            pdown={producerDown}
                                            three_way={threeWay}
                                        />
                                    </div>

                                <div className={`text-center mt-2 ${fetching ? 'd-block' : 'd-none'}`}>
                                    <Spinner animation={'grow'} size={'lg'}/>
                                </div>
                                </div>
                            </div>
                        </div>
        </>
    )
}

export default Index
