import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {FaFacebookSquare , FaInstagram, FaLinkedinIn, FaTwitter, FaYoutube} from "react-icons/fa"
import FooterLogo from '../../assets/img/footer-logo.svg';


const Footer = (props) => {

    return (
        <footer className="footer-custom">
            <section className='py-5'>
            <div className="container">

                <div className='row'>
                    <div className="col-md-3 col-sm-12 col-xs-12 col-lg-3">
                        <img src={FooterLogo} alt='' />
                    </div>

                    <div className='col-md-3 col-sm-12 col-xs-12 col-lg-3'>
                        <h1 className='primary-color mb-2'>Learn More</h1>
                        <ul className=''>
                            <li>
                                <a href='/help'>
                                    Help center
                                </a>
                            </li>
                            <li>
                                <a href='/help'>
                                    Terms and conditions
                                </a>
                            </li>
                            <li>
                                <a href='/help'>
                                    Responsible Gaming Policy
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className='col-md-3 col-sm-12 col-xs-12 col-lg-3'>
                        <h1 className='primary-color mb-2'>Play</h1>
                        <ul className=''>
                            <li>
                                <a href='/help'>
                                    Sports
                                </a>
                            </li>
                            <li>
                                <a href='/jackpots'>
                                   Jackpots
                                </a>
                            </li>
                            <li>
                                <a href='/casino'>
                                    Casino
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className='col-md-3 col-sm-12 col-xs-12 col-lg-3'>
                        <h1 className='primary-color mb-2'>Contact Us</h1>
                        <ul className=''>
                            <li>
                                <a href=''>
                                    Telephone <br/> +2547XXXXXXX
                                </a>

                            </li>
                            <li>
                                <a href=''>
                                    Email <br/> info@sportsmula.com
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <hr className='my-4 bottom-hr'/>

                <div className="row mt-5">
                    <div className='col-md-8 col-sm-12 col-xs-12 col-lg-8'>
                        <h1 className='primary-color mb-2'>Licence</h1>
                        <ul className='mt-5'>
                            <li>
                                <a href=''>
                                    xxxxxxxxxxxx
                                </a>

                            </li>
                        </ul>
                    </div>

                    <div className='col-md-4 col-sm-12 col-xs-12 col-lg-4'>
                        <h1 className='primary-color mb-2'>18+</h1>
                        <div className='mt-5'>
                            <p>
                                Must be 18 years of age or older to register or play at SportMula. Gambling may have adverse effects if not done with moderation.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            </section>
            <section className='border-t-2 mt-5 pt-5 border-gray-100' id='copyright'>
                <div className='container'>
                    <div className="row" id="navbar-collapse-main">
                        <div className="col-md-8 col-xs-12 opacity-60">
                            Copyright &copy; {new Date().getFullYear()} All rights Reserved.
                        </div>
                        <div className="col-md-4 col-xs-12">
                            <div className='float-end text-white'>
                            <a href='' className='inline-block px-2'>
                                    <FaFacebookSquare  color='#ffffff' size={20}/>
                                </a>
                                <a href='' className='inline-block px-2'>
                                    <FaInstagram  color='#ffffff' size={20}/>
                                </a>
                                <a href='' className='inline-block px-2'>
                                    <FaTwitter  color='#ffffff' size={20}/>
                                </a>
                                <a href='' className='inline-block px-2'>
                                    <FaYoutube  color='#ffffff' size={20}/>
                                </a>
                                <a href='' className='inline-block px-2'>
                                    <FaLinkedinIn  color='#ffffff' size={20}/>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
        </footer>
    )
}

export default Footer
