import React, {useEffect} from "react";
import 'react-accessible-accordion/dist/fancy-example.css';
import DownloadLink from "react-download-link";
import mobileBanner from '../../../assets/img/mobile/APPDownload.png'
import {Link} from "react-router-dom";
import useAnalyticsEventTracker from "../../analytics/useAnalyticsEventTracker";
import Row from 'react-bootstrap/Row';

const Header = React.lazy(() => import('../../header/header'));
const SideBar = React.lazy(() => import('../../sidebar/awesome/Sidebar'));
const Footer = React.lazy(() => import('../../footer/footer'));
const Right = React.lazy(() => import('../../right/index'));
const downloadAPKFile = null; // React.lazy(() => import('../../../assets/sportmula.apk'));


const MobileApp = () => {
    const gaEventTracker = useAnalyticsEventTracker('App');

    useEffect(() => {
        gaEventTracker('App Page')
    })

    const getDownloadFile = () => {
        return downloadAPKFile;
    }

    return (
        <>
            
                        <div className="">
                            <div
                                className='profound-text col-md-12 page-title py-3 text-center d-flex flex-row justify-content-between sticky-top'>
                                <h4 className="inline-block">
SPORTMULA APP
                                </h4>

                                <Link to={'/sportmula.apk'}
                                      target={"_blank"}
                                      title={'Download App'}
                                      download={'sportmula.apk'}
                                      className="btn secondary-bg text-white btn-lg my-2 text-decoration-none"
                                      label="Download App"
                                      filename="sportmula.apk"
                                      onClick={()=>gaEventTracker('Downloaded App')}
                                      exportFile={() => getDownloadFile()}>Download sportmula App</Link>
</div>
                            <div className="col-md-12 mt-2 accordion-container text-start">
                                <hr/>
                                <div className={'d-flex justify-content-center shadow-sm mb-5'}>

                                  Download the SportMula APP🔥 now!!
                                    FOR BETTER EXPERIENCE
                                    Take Control of Your Bets

                                    Download & Get 50/= Instant BONUS.
                                </div>
                                <div className={'col-md-12 justify-content-center d-flex'}>
                                    <img src={mobileBanner} style={{width: "80%"}} className={'rounded-3 shadow-sm'}/>
                                </div>
                                <div className="col p-2">
                                    <br/>✅ Only 2MB in size
                                    <br/>✅ Faster & Lighter - Uses less data with data saver mode to play LIVE games.

                                      <br/>✅ Enjoy Instant Winnings on SportMula Virtuals.
                                    <br/>✅ Enjoy Instant Deposits and Fast Payouts only on SportMula App!
                                    <br/>✅ Livescore - Stay updated with accurate and real time match results.
                                    <br/>✅ Enjoy Light & Dark Theme Mode.
                                    <br/>✅ Instant Notifications - Don’t Miss Out on SportMula Offers and News Updates.
                                </div>
                                <div className="col">
                                    <hr/>
                                    Play & Start Winning on the Fastest ⚡️ & Lightest Betting App.
                                    <hr/>
                                </div>
                                <div className="text-center mt-2 col">
                                    <DownloadLink
                                        className="btn btn-primary btn-lg mb-5 text-white btn-lg col-md-3 text-decoration-none"
                                        label="Download App Now"
                                        filename="sportmula.apk"
                                        exportFile={() => getDownloadFile()}
                                    />
                                </div>
                            </div>
                        </div>
                    
        </>
    )
}

export default MobileApp
